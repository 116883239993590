import { AJAX_GET, AJAX_POST } from '../../helpers/ajax'

export default {

  async load(mode, id){
    return await AJAX_GET(`/${mode}/${id}`)
  },

  async locations(mode, id){
    return await AJAX_GET(`/${mode}/${id}/locations`)
  },

  async locationItems(mode, id, location){
    return await AJAX_GET(`/${mode}/${id}/locations/${location}`)
  },

  async summary(mode, id){
    return await AJAX_GET(`/${mode}/${id}/summary`)
  },

  async performAction(type, id, key, action){
    return await AJAX_POST(`my-jobs/review/${type}/${id}?key=${key}&action=${action}`)
  },

}
