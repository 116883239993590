<script setup>
import { ref } from "@vue/reactivity";
import { watch, computed } from "@vue/runtime-core";
import { defineProps, defineEmits } from 'vue'

const currenFinance = ref(null);
const emits = defineEmits(["change"]);
const props = defineProps({
  finance: {
    required: true,
    type: Object,
  },
  printWindow: {
    type: Boolean,
    default: false,
  },
});

let filteredData = computed(() => {
  return Object.fromEntries(
    Object.entries(props.finance[currenFinance.value]).filter(
      ([key, value]) =>
        ((value.amount && value.amount != 0) || value.header === true) &&
        value.visible !== false
    )
  );
});

const changeTab = (i) => {
  currenFinance.value = i;
  emits("change", i);
};
watch(
  props.finance,
  (newVal) => {
    if (newVal && Object.keys(newVal).length > 0 && !currenFinance.value)
      changeTab(Object.keys(newVal)[0]);
  },
  { immediate: true }
);

</script>

<template>
  <div v-if="finance && currenFinance">
    <h4 class="h4">Order summary</h4>
    <ul v-if="Object.keys(finance).length > 1" class="tabs no_print">
      <li
        v-for="(type, j) in finance"
        :key="j"
        class="tabs__item"
        @click="changeTab(j)"
      >
        <span
          class="tabs__link"
          :class="{ tabs__link_active: currenFinance === j }"
          >{{ j }}</span
        >
      </li>
    </ul>
    <table cellpadding="5" cellspacing="5" class="price">
      <tr v-for="(i, j) in filteredData" :key="j">
        <td
          :colspan="{ '2': i.header }"
          :class="{ strong: i.bold || i.header }"
        >
          <span v-if="i.name">{{ i.name }}</span>
          <span v-else>{{ j }}</span>
          <p v-if="i.note" class="h6_small">*{{ i.note }}</p>
        </td>
        <td v-if="!i.header" class="h6_right text_right">
          <span v-if="i.format === 'percent'" :class="{ strong: i.bold }">
            {{
              Number(i.amount)
                .toFixed(1)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            }}%
          </span>
          <span v-else :class="{ strong: i.bold }">
            $
            {{
              Number(i.amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            }}
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "SummaryTabs",
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/utils/vars";

@media screen and (max-width: 576px) {
  .tabs {
    flex-direction: row;
  }
}

.price {
  width: 100%;
}

.strong {
  font-weight: bold;
}

@media print {
  .container {
    font-size: 11px !important;
  }

  .no_print {
    display: none;
  }
}
</style>
