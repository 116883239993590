

<template>
  <div class="container">
    <div class="row" v-if="!status ">
      <h1>Loading data, please wait...</h1>
      <Loader />
    </div>
    <div class="row" v-else-if="status === 'Approved'">
      <h1 class="approved">
        <svg-icon name="solid/check" />
        {{type}} has been approved</h1>
    </div>
    <div class="row" v-else-if="status === 'Rejected'">
      <h1 class="rejected">
        <svg-icon name="solid/times" />
        {{type}} has been rejected</h1>
    </div>
    <div class="row voided" v-else-if="status === 'Voided'">

      <h1 class="voided"><svg-icon name="solid/warning" />
        {{type}} has been updated since it was sent. See new details below. Please contact dealer for more information.</h1>
    </div>
    <div class="row" v-else-if="status === 'ERROR'">
      <h1 class="error">{{statusMessage}}</h1>
    </div>

    <div class="row" v-if="status && status !== 'ERROR'">
      <section>
        <div class="row header-info">
          <div
            class="col-md-4 tw-mb-4 md:tw-mb-0"
          >
            <h4 class="h4">
              {{ type }} Number: {{ data.order_id }}
            </h4>
            <p>Date: {{ data.order_date }}</p>

            <h4 class="h4 mt-2- tw-mt-4 sm:tw-mt-2">Dealer</h4>
            <p v-if="data.dealer">Dealer: {{ data.dealer.name }}</p>

            <p v-if="data.dealer">Email: {{ data.dealer.email }}</p>
            <p v-if="data.dealer">Phone: {{ data.dealer.phone }}</p>
          </div>
          <div class="col-md-4">
            <h4 class="h4">Customer</h4>
            <div v-if="data && data.customer && Object.keys(data.customer).length > 0">
              <p>Name: {{ data.customer.name }}</p>
              <p>Email: {{ data.customer.email }}</p>
              <p>Phone: {{ data.customer.phone }}</p>
              <p>Address: {{ data.customer.address }}</p>
            </div>
            <div v-else>No customer</div>
          </div>

          <div
            class="col-md-4 tw-mt-4"
            v-if="data.finance"
          >
            <SummaryTabs
              :finance="data.finance"
            />
          </div>
        </div>
        <div
          class="top-action top-action tw-z-10 tw-w-full tw-bg-white tw-transition-all tw-sticky tw-top-12"
        >

        </div>
        <div class="row mt-2">
          <div class="col-12">
            <h4 class="h4">Product Details</h4>
          </div>
        </div>

        <div
          class="row small item-container"
          v-for="(item, i) in mainItemsRemapped"
          :key="i"
        >
          <hr class="divider mx-2" />

          <div class="col-12">
            <h4 class="h4">{{ item.name }}</h4>
          </div>
          <div class="col-md-1 item-icon">
            <svg-icon-qouting  :data="item.layout_image || item.icon" alt="" />
          </div>
          <div class="col-md-5">
            <div v-for="(val, key) in item.col1" :key="key">
              <strong>{{ key }}: </strong>
              <span v-if="key !== 'Out of Square File'" v-html="val"></span>
            </div>
          </div>

          <div class="col-md-6">
            <div v-for="(val, key) in filterNotOSQR(item.col2)" :key="key">
              <strong>{{ key }}: </strong>
              <span v-html="val"></span>
            </div>
          </div>
          <div
            class="col-md-6 text_right"
            v-if="item.finance"
          >
          </div>
          <div
            class="col-md-6 text_right"
            v-if="item.finance"
          >
            <p class="text_print">
              Price ${{
                Number(item.finance["customer"]["Product Price"].amount)
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              }}
            </p>
          </div>
        </div>

        <div
          class="row"
          v-if="data.accessories && data.accessories.length > 0"
          style="page-break-inside: avoid"
        >
          <div class="col-12">
            <h4 class="h4">Additional Accessories</h4>
          </div>
          <div class="col-12">
            <div class="row" v-for="(accessory, key) in data.accessories" :key="key">
              <div class="col-md-6">
                {{ Number(accessory.qty).toFixed(0) }} x {{ accessory.name }} -
                {{ accessory.colour_name }}
              </div>
              <div class="col-md-3 text_right">
              </div>
              <div class="col-md-3 text_right">
                <span class="text_print"
                >Price ${{
                    Number(accessory.finance["customer"].price)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="status === 'Opened'" class="row">
          <div class="col">
            <hr/>
            <button class="btn btn_default" type="button" @click="approve" >
              <svg-icon name="solid/check"/>
              &nbsp;Approve
            </button>
            <button class="btn" type="button" @click="reject">
              <svg-icon name="solid/times"/>
              &nbsp;Reject
            </button>
          </div>
        </div>

      </section>

    </div>

  </div>
</template>

<script>
import Loader from "../../elements/Loader.vue";
import {ref, computed} from 'vue'
import { useRoute } from "vue-router";
import api from '../../../api/orders/preview'
import SummaryTabs from "../specification/SummaryTabs.vue";
import router from "@/router";
import { useToast } from "vue-toastification";

export default({
  props: [],
  components: {
    Loader,
    SummaryTabs
  },
  setup(props, {emit}) {
    const status = ref(null)
    const statusMessage = ref(null)
    const route = useRoute()
    const data = ref(null)
    const type = ref(route.params.type)
    const order_num = ref(0);
    const mainItems = ref([]);
    const toast = useToast();


    async function getData(){
      return api.performAction(route.params.type, route.params.id, route.query.key, route.query.action)
        .then(response => {
          status.value = response.status
          data.value = response.order_data
          order_num.value = response.order_data.order_id; //items.order_id;
          mainItems.value = response.order_data.mainItems; //items.mainItems;
        }).catch(response => {
          status.value = 'ERROR'
          switch (response.status){
            case 404:
              statusMessage.value = route.params.type + ' not found'
              break
            case 403:
              statusMessage.value = 'You are not authorised to view this ' + route.params.type.toLower()
              break
            default:
              statusMessage.value = 'Unknown error'
          }
        })
    }

    getData()

    const approve = () => {
      router.push({
        name: 'view-quote',
        params: {
          type: route.params.type,
          id : route.params.id
        },
        query: {
          key: route.query.key,
          action: 'approve'
        }
      }).then(() => {
        getData().then(() => {
          if (status.value === 'Approved' || status.value === 'Rejected') {
            toast.success(type.value + ' has been ' + status.value.toLowerCase())
          }
        })
      })
    }

    const reject = () => {
      router.push({
        name: 'view-quote',
        params: {
          type: route.params.type,
          id : route.params.id
        },
        query: {
          key: route.query.key,
          action: 'reject'
        }
      }).then(() => {
        getData().then(() => {
          if (status.value === 'Approved' || status.value === 'Rejected') {
            toast.success(type.value + ' has been ' + status.value.toLowerCase())
          }
        })
      })
    }

    const mainItemsRemapped = computed(() => {
      if (!data.value.mainItems) return;
      return Object.values(data.value.mainItems).map((item) => {
        item.col1 = {};
        item.col2 = {};
        const all = { ...item.required, ...item.optional };
        const keys = Object.keys(all);
        keys.slice(0, keys.length / 2 + 1).forEach((key) => {
          item.col1[key] = all[key];
        });
        keys.slice(keys.length / 2 + 1).forEach((key) => {
          item.col2[key] = all[key];
        });
        return item;
      });
    });

    let filterNotOSQR = (object) => {
      return Object.fromEntries(
        Object.entries(object).filter(([key, value]) => {
          // return key !== "Out of Square Table";
          return !["Out of Square Table", "Out of Square File"].includes(key);
        })
      );
    };
    return {
      status,
      statusMessage,
      data,
      type,
      order_num,
      mainItems,
      mainItemsRemapped,
      filterNotOSQR,
      approve,
      reject
    }

  }
  })

</script>

<style scoped lang="scss">
.item-icon {
  max-width: 100px;
  width: auto;
  // max-height: 100px;

  ::v-deep svg {
    max-width: 100%;
    width: 100%!important;
    height: auto;
    vertical-align: top;
  }
}

h4{
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding-bottom: 7px;
}

h1{
  width: 100%;
  padding: 25px;
  border-bottom: solid 1px #0B3553;
  margin-bottom: 10px;

  &.approved{
    background-color: #1A9DB77F;
  }

  &.rejected{
    background-color: #b538364C;
  }

  &.voided{
    background-color: #F1C40F7F;
  }
}
</style>
